:root {
  --register-container: 100%;
  --clr-white: #fff;
  --clr-brown-1: #d67c1c;
  --clr-brown-2: rgba(115, 83, 24, 1);
  --clr-yellow-1: #bd15;
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", sans-serif;
}

@media screen and (min-width: 640px) {
  :root {
    --register-container: 640px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --register-container: 768px;
  }
}

ul,
li {
  list-style: none;
}
.register-screen {
  width: 100%;
  margin-top: 4rem;
  position: relative;
  overflow: hidden;
}
.register-screen::before {
  position: relative;
}

.register-screen .register-container {
  max-width: var(--register-container);
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-flow: column-reverse wrap;
  background-color: var(--clr-white);
  box-shadow: var(--dark-shadow);
  border-radius: 10px;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .register-screen .curve-circle {
    position: absolute;
    height: 800px;
    width: 800px;
    border-radius: 50%;
    background-color: var(--clr-brown-2);
    top: -201px;
    right: -362px;
  }
}

.register-container > * {
  position: relative;
}

@media screen and (min-width: 768px) {
  .register-screen .register-container {
    flex-flow: row nowrap;
  }
}

.register-left {
  width: 100%;
  padding-left: 20px;
}
.register-left .header {
  color: var(--clr-brown-2);
}
.register-right {
  width: 100%;
  color: var(--clr-brown-2);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .register-left {
    width: 40%;
  }
  .register-right {
    width: 40%;
    color: var(--clr-white);
    text-align: left;
  }
}

.form-control {
  margin-bottom: 20px;
}

.form-control input {
  width: 100%;
  height: 40px !important;
}

@media screen and (min-width: 768px) {
  .form-control input {
    max-width: 400px;
  }
}

.form-control label {
  font-weight: 600;
  color: var(--clr-brown-2);
  margin-bottom: 5px;
}

.register-left .button-position {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.register-right h2 {
  font-size: 40px;
}

.register-right p {
  font-size: 16px;
  font-weight: 600;
}

.register-right h3 {
  font-size: 20;
}

.errMsg {
  color: red;
  padding: 10px;
  transition: ease-in 5s;

  /* border: 1px solid red; */
}

