body {
  background-color: #EAEAEA !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
            135deg, 
              rgb(115, 83, 24) 0%,
              rgb(174, 141, 33) 69%,
              rgb(172, 158, 32) 89%) !important;
             /* rgb(24,42,115) 0%,
             rgb(33, 138, 174) 69%,
             rgb(32,167,172) 89%) !important; */
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
          135deg,
          rgb(115, 83, 24) 0%,
          rgb(174, 141, 33) 69%,
          rgb(172, 158, 32) 89%
          /* rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89% */
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/
