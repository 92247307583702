.edit-btn {
  background-color: transparent !important;
  padding: 0.2rem 0.3rem !important;
  text-decoration: none !important;
  border-radius: 5px;
  height: 30px !important;
}

.edit-btn {
  color: #a5673f !important;
  border: 1px solid #a5673f !important;
}
.edit-btn:hover {
  color: #fff !important;
  background-color: #a5673f !important;
}

.dx-item .dx-box-item {
  flex-direction: row !important;
  justify-content: flex-start !important;
}
