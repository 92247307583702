/* import classes from "./coster.module.css" */

:root {
  --border-light: inset 0px 0px 0px 1px #000;
  --border-bold: inset 0px 0px 0px 2px #000;
  --green-text: rgb(22 101 52);
  --green-background: rgb(34 197 94);
  --readonly-grey: rgb(156 163 175);
  --cell-padding: 0.2em 0.78571429em;
}

/* Header */
.accordion_width{
  width: 100% !important;
}

.company {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  box-shadow: var(--border-light);
}

.company .logo {
  max-width: 50px;
}

.company .name {
  text-align: center;
}

.company .name h3 {
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
}

.company .name p {
  font-size: 1em;
  font-weight: 600;
}

/* Contract Information */

header.header {
  font-size: 1.8em;
  background-color: rgb(156 163 175);
  box-shadow: var(--border-light);
  padding: 0.5em 0.2em;
  font-weight: 700;
}

table tr td:first-child:not(.ignored) {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
}

tr td,
.ui.celled.table tr th {
  border-left: 3px solid rgba(34, 36, 38, 0.1);
}

.border {
  box-shadow: var(--border-bold);
  margin: 0 !important ;
}

div.column {
  padding: 0 !important;
}

.row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.column {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.row_border td:first-child {
  border-top: none !important;
}

.row_border_2 td:nth-child(2) {
  border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-top: none !important;
  padding-left: 0 !important;
}

tbody .row_border_3:not(:first-child) td:nth-child(2) {
  border-top: 2px solid rgba(0, 0, 0, 0.6) !important;
}

tbody .row_border_3 td:nth-child(2) {
  border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_header_1 {
  background: #f9fafb;
  border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_header_2 {
  background: #f9fafb;
  border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-top: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_1 {
  margin: 0 !important;
}

/* Commodity Information */

.table_header_3 {
  /* background: #21ba45 !important; */
  background: var(--green-background) !important;
  font-weight: 800 !important;

  border-color: rgba(255, 255, 255, 0.1) !important;
}

tbody .row_border_4 td {
  border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
}

tbody .row_border_4 td:not(:first-child) {
  font-weight: 800 !important;
}

.table_footer_1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.table_footer_1 th {
  font-size: 1em;
  font-weight: 800 !important;
}

.table_footer_1 th:nth-child(2) {
  background: var(--green-background) !important;
  color: var(--green-text) !important;
}

.table_footer_1 th:nth-child(2) span {
  padding-left: 0.4em;
}

.readonly_cell_1 {
  background: var(--readonly-grey);
}

/* Expense Details */

/* Analysis */

.row_border_5 td {
  border-top: none !important;
  border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7) !important;
  padding: var(--cell-padding) !important;
}

.row_border_5 td:first-child {
  border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.column_2 {
  padding: 0 !important;
}

.readonly_cell_2 {
  background: var(--readonly-grey);
  font-weight: 800 !important;
}

.active_cell_1 {
  background: var(--green-background) !important;
  color: var(--green-text) !important;
  font-weight: 800 !important;
}

header.header_2 {
  text-align: center;
}

.profit_box {
  margin: 0 auto;
  box-shadow: var(--border-light);
  max-width: 250px;
}

.profit_box h3,
.profit_box p {
  padding: 0.5em;
  font-weight: 800 !important;
}

.profit_box h3 {
  font-size: 1.5em;
  border-bottom: var(--border-light);
  text-align: center;
  margin: 0;
}

.profit_box p {
  background: var(--green-background) !important;
  color: var(--green-text) !important;
  font-size: 1.6em !important;
}

.profit_box p span {
  font-size: 1.6em !important;
}

.profit_box p span:first-child {
  text-align: left;
  margin-right: 0.4em;
}

/* Summary */

.header_3 {
  background: var(--green-background) !important;
  color: var(--green-text) !important;
  font-size: 1.8em;
  box-shadow: var(--border-light);
  padding: 0.5em 0.2em;
  font-weight: 700;
  text-align: center;
}

.paragraph_1 {
  font-size: 1.15em;
  font-weight: 500;
}

.summary_cell {
  padding: 0.2em !important;
  box-shadow: var(--border-light) !important;
}
.summary_cell span {
  padding-left: 0.7em !important;
}

.summary_cell p,
.summary_cell span {
  font-size: 1.02em;
}

/* 
https://video-react.js.org/components/player/

https://cookpete.com/react-player/

https://blinkplanapi.caderp.com/api/FileManagement/BlinkPlan_Videos/01_Personal_Details/mp4
*/
