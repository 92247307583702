:root {
    --border-light: inset 0px 0px 0px 1px #000;
    --border-bold: inset 0px 0px 0px 2px #000;
    /* --green-text: rgb(22 101 52); */
    /* --green-background: rgb(34 197 94); */
    /* --readonly-grey: rgb(156 163 175); */
    --cell-padding: 0.2em 0.78571429em;
}

.accordion_width {
    width: 100% !important;
}

/* Contract Information */

header.header {
    font-size: 1.8em;
    background-color: rgb(156 163 175);
    box-shadow: var(--border-light);
    padding: 0.5em 0.2em;
    font-weight: 700;
}

table.table{
    /* height: 100%; */
    border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
}

table tr td:first-child:not(.ignored) {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.95);
}

tr td,
.ui.celled.table tr th {
    border-left: 3px solid rgba(34, 36, 38, 0.1);
}

.border {
    box-shadow: var(--border-bold);
    margin: 0 !important;
}

div.column {
    padding: 0 !important;
}

.row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.column {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.row_border td:first-child {
    border-top: none !important;
}

.row_border_2 td:nth-child(2) {
    border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
    border-top: none !important;
    padding-left: 0 !important;
}

tbody .row_border_3:not(:first-child) td:nth-child(2) {
    border-top: 2px solid rgba(0, 0, 0, 0.6) !important;
}

tbody .row_border_3 td:nth-child(2) {
    border-left: 2px solid rgba(0, 0, 0, 0.7) !important;
    border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_header_1 {
    background: #f9fafb;
    border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_header_2 {
    background: #f9fafb;
    border-right: 2px solid rgba(0, 0, 0, 0.7) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
    border-top: 2px solid rgba(0, 0, 0, 0.7) !important;
}

.table_1 {
    margin: 0 !important;
}

/* Description Of Issues */
.header_3 {
    background: var(--green-background) !important;
    color: var(--green-text) !important;
    font-size: 1.8em;
    box-shadow: var(--border-light);
    padding: 0.5em 0.2em;
    font-weight: 700;
    text-align: left;
}

div.ticket_container{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

p.ticket_p{
    font-weight: 700;
    font-size: 1.15rem;
}

/* Recommended Solution */