.ui.steps .step.active {
  background: rgba(115, 83, 24, 1);
  color: #fff !important;
}

.ui.steps .step.active .title {
  color: #fff;
}

.ui.steps .step.active .description {
  color: #fff;
}

.ui.steps .step.active .ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
  color: #fff;
}

.ui.steps a.active.step:hover {
  background: rgba(115, 83, 24, 1);
}

/* .bc__dx__label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  transition: background 0.1s ease;
  font-size: 0.85714286rem;
} */

/* .ui.steps .link.active.step:hover */
/* .ui.steps .link.active.step:hover::after */
/* .ui.steps  */
/* a.active.step:hover::after */
